<template>
  <v-container>
    <app-home-dashboard :key="dashboardKey"></app-home-dashboard>
  </v-container>
</template>

<script>
import HomeDashboard from '../components/HomeDashboard.vue'

export default {
  name: 'Home',
  components: {
    'app-home-dashboard': HomeDashboard
  },
  props: {
    label: {
      type: String,
      required: false,
    }
  },
  setup({ label }) {
    console.log("label", label);
    return {
      dashboardKey: label,
    }
  }
}
</script>

<style>

</style>